import React from 'react'

import Container from '~/components/Container'
import SocialLink from '~/components/SocialLink'
import useInstagramFeed from './hooks/useInstagramFeed'

import FollowUs from '~/images/follow-us.svg'

import * as classes from './InstagramFeed.module.scss'
import InstagramPost from './InstagramPost'

const InstagramFeed = () => {
  const [posts, loading] = useInstagramFeed()

  if (loading || posts.length === 0) {
    return null
  }

  return (
    <section className={classes.instagramFeed}>
      <Container className={classes.container}>
        <div className={classes.leftSocial}>
          <div className={classes.followUsContainer}>
            <img src={FollowUs} alt="Follow us on Social" />
          </div>
          <SocialLink className={classes.socialFeed} color={'blue'} />
        </div>
        <div className={classes.feed}>
          {posts.map((post, index) => (
            <div className={classes.feedItem} key={post.media_url}>
              <InstagramPost
                url={post.media_url}
                caption={post.caption}
                link={post.permalink}
                size={(index === 1 || index === 2) && 'small'}
              />
            </div>
          ))}
        </div>
      </Container>
    </section>
  )
}

export default InstagramFeed

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Navigation, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import Container from '~/components/Container'

import * as classes from './Slider.module.scss'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'

const Slider = ({ slides }) => {
  const [slideIndexActive, setSlideIndexActive] = useState(0)

  if (!slides) {
    return null
  }

  return (
    <>
      <Swiper
        modules={[Navigation, A11y]}
        navigation
        className={classes.mobileSlider}
        loop={true}
      >
        {slides.map((slide, index) => (
          <SwiperSlide
            className={classnames(classes.slideImage, {
              [classes.slideImageActive]: index === slideIndexActive,
            })}
            key={`${slide.slide_decription.text}-${slide.slide_link.uid}`}
          >
            <Link
              className={classes.leftSideSingleSlide}
              to={`/${slide.slide_link.uid}`}
            >
              <h3>{slide.slide_title.text}</h3>
              <p>{slide.slide_decription.text}</p>
            </Link>
            <GatsbyImage
              image={slide.slide_image.gatsbyImageData}
              alt={slide.slide_image.alt || slide.slide_title.text}
              layout={'fullWidth'}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <section
        className={classes.sliderContainer}
        onMouseLeave={() => setSlideIndexActive(0)}
        role="presentation"
      >
        <Container className={classes.sliderContainerMain}>
          <div className={classes.leftSideSlider}>
            {slides.map((slide, index) => (
              <Link
                className={classes.leftSideSingleSlide}
                to={`/${slide.slide_link.uid}`}
                key={`${slide.slide_title.text}-${slide.slide_link.uid}`}
                onMouseEnter={() => setSlideIndexActive(index)}
              >
                <h3>{slide.slide_title.text}</h3>
                <p>{slide.slide_decription.text}</p>
              </Link>
            ))}
          </div>
          <div className={classes.sliderImageContainer}>
            {slides.map((slide, index) => (
              <div
                className={classnames(classes.slideImage, {
                  [classes.slideImageActive]: index === slideIndexActive,
                })}
                key={index}
              >
                <GatsbyImage
                  image={slide.slide_image.gatsbyImageData}
                  alt={slide.slide_image.alt || slide.slide_title.text}
                  layout={'fullWidth'}
                />
              </div>
            ))}
          </div>
        </Container>
      </section>
    </>
  )
}

Slider.prototype = {
  slides: PropTypes.arrayOf.isRequired,
}

export default Slider

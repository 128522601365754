import { useEffect, useState } from 'react'
import axios from 'axios'

const INSTAGRAM_POST_URL = `https://graph.instagram.com/4182075208585821/media?fields=caption,media_count,media_type,permalink,media_url,timestamp&access_token=${process.env.INSTAGRAM_TOKEN}`

const fetchInstagramPostCall = async () => {
  try {
    let apiReturn = await axios
      .get(INSTAGRAM_POST_URL)
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.error(error)
      })
    return apiReturn
  } catch (e) {
    console.error(e)
  }
}

const storage_insta_key = 'instagram_post'

const useInstagramFeed = () => {
  const [instagramPost, setInstagramPost] = useState([])
  const [loading, setLoading] = useState(true)

  const tomorrow = new Date(Date.now() + 3600 * 1000 * 24)

  useEffect(async () => {
    setLoading(true)

    const existingPost = localStorage.getItem(storage_insta_key) || null

    if (existingPost && existingPost.length !== 0) {
      // Get instagram post from local storage
      setInstagramPost(
        JSON.parse(localStorage.getItem(storage_insta_key)).value
      )
      setLoading(false)
    } else {
      try {
        const response = await fetchInstagramPostCall()
        const posts = response?.data.slice(0, 4) || []
        setInstagramPost(posts)
        const valueForStorage = {
          value: posts,
          expiry: tomorrow.getTime(), // set for 24h
        }
        // cache instagram post in local storage
        localStorage.setItem(storage_insta_key, JSON.stringify(valueForStorage))
        setLoading(false)
      } catch (e) {
        console.error(e)
      }
    }
  }, [])

  return [instagramPost, loading]
}

export default useInstagramFeed

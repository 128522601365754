import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import * as classes from './InstagramPost.module.scss'

const InstagramPost = ({ caption, url, link, size }) => {
  if (!url) {
    return null
  }

  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className={classnames(classes.instagramPost, {
        [classes.small]: size === 'small',
      })}
    >
      <div className={classes.imgContainer}>
        <img src={url} alt={caption && caption} loading="lazy" />
      </div>
      {caption && (
        <div className={classes.captionContainer}>
          {size === 'small' ? (
            <p>
              {caption.length > 200 ? caption.slice(0, 200) + '...' : caption}
            </p>
          ) : (
            <p>{caption}</p>
          )}
        </div>
      )}
    </a>
  )
}

InstagramPost.prototype = {
  caption: PropTypes.string,
  url: PropTypes.string.isRequired,
  link: PropTypes.string,
  size: PropTypes.oneOf(['small', 'big']),
}

InstagramPost.default = {
  size: 'big',
}

export default InstagramPost

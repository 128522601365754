import React from 'react'
import classnames from 'classnames'
import { RichText } from 'prismic-reactjs'
import { GatsbyImage } from 'gatsby-plugin-image'
import Container from '~/components/Container'

import * as classes from './AboutSection.module.scss'

const AboutSection = ({ title, columns }) => {
  if (!columns) {
    return null
  }

  return (
    <section className={classes.threecolumns}>
      {title && <h2 className={classes.title}>{title.text}</h2>}
      <Container className={classes.threecolumnsContainer}>
        {columns.map((column, index) => {
          return (
            <div
              className={classnames(classes.singlecolumn)}
              key={column.about_column_title.text}
              role="presentation"
            >
              <GatsbyImage
                image={column.about_column_image.gatsbyImageData}
                alt={
                  column.about_column_image.alt ||
                  column.about_column_title.text
                }
                loading={`lazy`}
              />
              <div className={classes.infocolumn}>
                <h3>{column.about_column_title.text}</h3>
                <RichText render={column.about_column_info.raw} />
              </div>
            </div>
          )
        })}
      </Container>
    </section>
  )
}

export default AboutSection

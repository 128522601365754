import React from 'react'
import { graphql } from 'gatsby'

import Layout from '~/components/Layout'
import Seo from '~/components/Seo'
import Slider from '~/components/Slider'
import AboutSection from '~/components/AboutSection'
import ThreeBlocks from '~/components/ThreeBlocks'
import Testimonials from '~/components/Testimonials'

import InstagramFeed from '../../components/InstagramFeed'

const HomePage = ({ data }) => {
  const homePage = data.prismicHomepage.data

  const { seo_title: seoTitle, seo_description: seoDescription } = homePage

  return (
    <Layout isHomePage>
      <Seo title={seoTitle?.text} description={seoDescription?.text} />
      <Slider slides={homePage.slider} />
      <AboutSection
        title={homePage.about_title}
        columns={homePage.about_columns}
      />
      <ThreeBlocks blocks={homePage.related_content} />
      <Testimonials
        title={homePage.title_testimonials.text}
        testimonials={homePage.testimonials}
      />
      <InstagramFeed />
    </Layout>
  )
}

export default HomePage

export const query = graphql`
  query HomePage {
    prismicHomepage {
      data {
        slider {
          slide_decription {
            text
          }
          slide_image {
            gatsbyImageData(layout: CONSTRAINED, sizes: "70vw")
            alt
          }
          slide_link {
            uid
          }
          slide_title {
            text
          }
        }
        testimonials {
          testimonial_message {
            text
          }
          testimonial_title {
            text
          }
        }
        title_catch {
          text
        }
        title_testimonials {
          text
        }
        short_text_catch {
          text
        }
        seo_title {
          text
        }
        seo_description {
          text
        }
        related_content {
          related_content_title {
            text
          }
          related_content_link {
            uid
          }
          related_content_image {
            gatsbyImageData(
              layout: CONSTRAINED
              sizes: "30vw"
              aspectRatio: 1.77
            )
            alt
          }
          related_content_description {
            raw
          }
        }
        link_under_slider {
          uid
        }
        image_catch {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        about_columns {
          about_column_info {
            raw
          }
          about_column_title {
            text
          }
          about_column_image {
            gatsbyImageData(layout: CONSTRAINED, sizes: "180px")
            alt
          }
        }
        about_title {
          text
        }
      }
    }
  }
`

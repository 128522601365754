import React, { useState } from 'react'
import { navigate } from 'gatsby'
import classnames from 'classnames'
import { RichText } from 'prismic-reactjs'
import { GatsbyImage } from 'gatsby-plugin-image'
import Container from '~/components/Container'

import * as classes from './ThreeBlock.module.scss'
import ArrowLink from '../ArrowLink/ArrowLink'

const linksTitle = {
  0: 'Shop now',
  1: 'Find out more',
  2: 'Get in touch',
}

const ThreeBlocks = ({ blocks }) => {
  const [activeBlock, setActiveBlock] = useState(null)

  if (!blocks) {
    return null
  }

  return (
    <section className={classes.threeBlocks}>
      <Container className={classes.threeBlocksContainer}>
        {blocks.map((block, index) => {
          return (
            <div
              className={classnames(classes.singleBlock, {
                [classes.activeBlock]: activeBlock === index,
              })}
              key={index}
              onMouseEnter={() => setActiveBlock(index)}
              onMouseLeave={() => setActiveBlock(null)}
              onClick={() =>
                navigate(`/${block.related_content_link.uid || 'shop'}`)
              }
              onKeyPress={() =>
                navigate(`/${block.related_content_link.uid || 'shop'}`)
              }
              role="presentation"
            >
              <div className={classes.infoBLock}>
                <h3>{block.related_content_title.text}</h3>
                <RichText render={block.related_content_description.raw} />
              </div>
              <GatsbyImage
                onMouseEnter={() => setActiveBlock(index)}
                onMouseLeave={() => setActiveBlock(null)}
                image={block.related_content_image.gatsbyImageData}
                alt={block.related_content_title.text}
              />
              <div className={classes.linkContainer}>
                <ArrowLink
                  to={`/${block.related_content_link.uid || 'shop'}`}
                  text={linksTitle[index]}
                  color={'blue'}
                />
              </div>
            </div>
          )
        })}
      </Container>
    </section>
  )
}

export default ThreeBlocks

import React from 'react'
import PropTypes from 'prop-types'
import { Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import WordMouth from '~/images/word-mouth.svg'
import TestimonialsTop from '~/images/testimonial-section-top.svg'
import TestimonialsBottom from '~/images/testimonial-section-bottom.svg'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import * as classes from './Testimonials.module.scss'

const Testimonials = ({ title, testimonials }) => {
  if (!testimonials) {
    return null
  }

  return (
    <section className={classes.testimonials}>
      <div className={classes.testimonialsTop}>
        <img src={TestimonialsTop} alt="Testimonials" />
      </div>
      <div className={classes.container}>
        <div className={classes.imgWord}>
          <img src={WordMouth} alt={title} />
        </div>
        <div className={classes.swiperContainer}>
          <h2>{title}</h2>
          <Swiper
            modules={[Pagination, Scrollbar, A11y]}
            slidesPerView={'auto'}
            pagination={{ clickable: true }}
          >
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={testimonial.testimonial_title.text}>
                <p>{testimonial.testimonial_message.text}</p>
                <span>{testimonial.testimonial_title.text}</span>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className={classes.testimonialsBottom}>
        <img src={TestimonialsBottom} alt="" />
      </div>
    </section>
  )
}

Testimonials.propTypes = {
  title: PropTypes.string.isRequired,
  testimonials: PropTypes.array.isRequired,
}

export default Testimonials
